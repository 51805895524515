@import "~bootstrap/scss/bootstrap";

@include media-breakpoint-down(md) {
	.verify-wrapper>.verify-container {
		width: 90% !important;
	}
}

.verify-wrapper {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #f5f5f5;
}

.verify-wrapper>.verify-container {
	width: 50%;
	background: white;
	padding: 15px 30px;
	border-radius: 15px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.verify-wrapper>.verify-container>.header {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.verify-wrapper>.verify-container>.header>img {
	width: 100%;
	height: 100%;
}

.verify-wrapper>.verify-container>.header>h1 {
	font-size: 24px;
	text-align: center;
	margin: 10px;
	font-weight: bold;
}

.verify-wrapper>.verify-container>.content {
	text-align: center;
}

.verify-wrapper>.verify-container>.actions {
	display: flex;
	align-items: center;
	justify-content: center;
}

.verify-wrapper>.verify-container>.actions>button {
	background: #6773E7;
	padding: 10px;
	font-size: 1.25rem;
	color: white;
	border-radius: 5px;
	cursor: pointer;
}

.verify-wrapper>.verify-container>.actions>button:nth-last-child(1) {
	background-color: #23374D;
}