.tab-content {
	width: 100%;
}

#block {
	width: 100%;
}

.tab-content>.tab-pane {
	border-radius: 10px;
	width: 100%;
}

.tab-content>.tab-pane>.tab {
	margin: 5px;
	padding: 2px;
	background-color: white;
	border-radius: 10px;
	box-shadow: 0px 0px 10px -6px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 0px 0px 10px -6px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 10px -6px rgba(0, 0, 0, 0.75);
}