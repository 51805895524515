@import "~bootstrap/scss/bootstrap";

@include media-breakpoint-down(md) {
	.topbar-wrapper {
		margin-top: 10px !important;
		padding: 0;
		flex-direction: column-reverse;
		align-items: flex-start !important;
		justify-content: space-around !important;
	}

	.page-title>h1 {
		font-size: 1rem !important;
	}

	.topbar-wrapper>.profile-action>button {
		display: none;
	}

	.topbar-wrapper>.profile-action>.userInfo {
		margin-left: 0px !important;

		div {
			img {
				width: 30px !important;
				height: 30px !important;
			}

			span {
				width: 10px !important;
				height: 10px !important;
				border: 1px solid #fff;
			}
		}

		h1 {
			font-size: 0.8rem !important;
		}

		svg {
			font-size: 0.8rem !important;
		}
	}

	.topbar-wrapper>.profile-action>.userInfo>.profile-menu {
		top: 40px;
		padding: 10px;

		h3 {
			font-size: 12px;
		}
	}
}


.topbar-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 5px;
}

.topbar-wrapper>.page-title>p {
	color: #222943;
	font-size: 1.2rem;
	font-weight: 600;
	letter-spacing: 2px;
	margin: 0;
}

.topbar-wrapper>.profile-action {
	display: flex;
	align-items: center;
	justify-content: center;
}

.topbar-wrapper>.profile-action>button {
	background: #6773E7;
	color: #fff;
	border: none;
	outline: none;
	font-size: 1rem;
	font-weight: 400;
	letter-spacing: 1px;
	padding: 8px;
	border-radius: 5px;
	cursor: pointer;
}

.topbar-wrapper>.profile-action>.userInfo {
	display: flex;
	align-items: center;
	margin-left: 20px;
	position: relative;
}

.topbar-wrapper>.profile-action>.userInfo>h1 {
	margin-left: 10px;
	margin-right: 5px;
	font-size: 1.25rem;
	cursor: pointer;
	font-weight: 600;
}

.topbar-wrapper>.profile-action>.userInfo>svg {
	font-size: 1rem;
	color: #6773E7;
	margin-right: 10px;
	cursor: pointer;
}

.topbar-wrapper>.profile-action>.userInfo>div {
	position: relative;
	cursor: pointer;
}

.topbar-wrapper>.profile-action>.userInfo>div>img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

.topbar-wrapper>.profile-action>.userInfo>.profile-menu {
	position: absolute !important;
	top: 40px;
	right: 0;
	background: white;
	z-index: 9999;
	padding: 10px;
	box-shadow: 0px 1px 2px #00000024;
	border-radius: 5px;
}

.topbar-wrapper>.profile-action>.userInfo>.profile-menu>p {
	font-size: 15px;
	font-weight: 400;
	border-bottom: 0.2px solid #00000024;
	margin: 0;
	padding: 10px 0;
}

.topbar-wrapper>.profile-action>.userInfo>.profile-menu:last-child {
	border: none;
}