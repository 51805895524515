.create-project-container {
    background: white;
    padding: 10px;
    width: 100%;
}

.create-project-container>.create-project-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.create-project-container>.create-project-form>div {
    margin: 0 10px;
    position: relative;
    width: 100%;
}

.create-project-container>.create-project-form>div>.inputField,
.create-project-container>.create-project-form>#create-project-select>div>.inputField {
    border: 1px solid;
    font-size: 14px;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 20px;
}

.create-project-container button {
    padding: 10px;
    border: none;
    outline: none;
    background: #6773E7;
    color: white;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    width: fit-content;
}

.create-project-container>.create-project-form>#create-project-select>.model_box {
    display: flex;
    align-items: center;
}

.create-project-container>.create-project-form>#create-project-select>.model_box>label {
    margin: 10px;
}