@import "~bootstrap/scss/bootstrap";

@include media-breakpoint-down(md) {
	.notification-card {
		padding: 20px 15px;
	}

	.notification-card>.notification {
		padding: 10px 0;

		span {
			width: 10px;
			height: 10px;
		}

		div {
			margin-left: 45px;
			width: calc(100% - 25px);

			h3 {
				font-size: 14px;
			}

			p {
				font-size: 12px;
			}
		}

		svg {
			font-size: 1.5rem;
		}
	}
}

.notification-card {
	padding: 30px 30px;
}

.notification-card>.notification {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	position: relative;
	padding: 15px 0;
	border-bottom: 0.5px solid #e6e6e6;

	span {
		display: none;
		width: 20px;
		height: 20px;
		background: #6773E7;
		border-radius: 50%;
		position: absolute;
		top: 20px;
		left: 15px;
	}

	div {
		margin-left: 50px;
		width: calc(100% - 50px);

		h3 {
			font-size: 18px;
			font-weight: 500;
			text-transform: uppercase;
			margin: 0;
			color: #00000066;
		}

		p {
			font-size: 16px;
			width: 80%;
			margin: 0;
			font-weight: 400;
			color: #00000066;
		}
	}

	svg {
		position: absolute;
		font-size: 2rem;
		cursor: pointer;
		right: 0;

		&:hover {
			opacity: 0.8;
		}
	}

	:last-child {
		border-bottom: none;
	}

}

.notification-card>.notification-placeholder {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.notification-card>.notification-placeholder>img {
	height: 50vh;
	width: 80%;
}