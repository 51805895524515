.qr-poster {
	height: 700px;
	width: 650px;
	background-color: #5E5CCD;
	padding: 30px 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.qr-poster>p {
	margin-top: 20px;

}

.qr-poster>p>a {
	color: white;
	margin: 0;
	text-decoration: none;
}

.qr-poster>.qr-box {
	background-color: white;
	width: 600px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.qr-poster>.qr-box>.logo {
	text-align: center;
}

.qr-poster>.qr-box>.logo>img {
	height: 100px;
	width: 300px;
	padding-bottom: 5px;
	border-bottom: 2px solid #8381d6;
}

.qr-poster>.qr-box>#qr-code>img {

	/*Irrelevant code*/
	width: fit-content;
	height: fit-content;
	box-sizing: border-box;
	margin: 5px;
	display: inline-flex;
	font-size: 30px;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 10px;
}

.qr-poster>.qr-box>p {
	color: black;
	font-family: 'Montserrat';
	font-size: 15px;
	font-weight: bold;
}