.register-success-wrapper {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #f5f5f5;
}

.register-success-wrapper>.register-success-form {
	width: 510px;
	background: white;
	padding: 15px 30px;
	border-radius: 15px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
	align-items: center;
}

.register-success-wrapper>.register-success-form>h1 {
	font-size: 24px;
	text-align: center;
	margin: 0;
	font-weight: bold;
}

.register-success-wrapper>.register-success-form>h4 {
	text-align: center;
	font-size: 16px;
	font-weight: 400;
}

.register-success-wrapper>.register-success-form>img {
	width: 150;
}