.post-registration {
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #f5f5f5;
}


.post-registration>.form-box {
	background: white;
	padding: 45px 30px;
	border-radius: 15px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.post-registration>.form-box>.input-group>.input-group-text {
	margin: 10px;
	margin-right: 0;
	padding: 10px;
}

.post-registration>.form-box>.input-group>input {
	font-size: 22px;
	padding: 10px;
	margin: 10px;
	line-height: 55px;
}

.post-registration>.form-box>.username-actions>button {
	padding: 10px 25px;
	border: none;
	outline: none;
	background-color: #5E5CCD;
	font-size: 1.25rem;
	border-radius: 5px !important;
	cursor: pointer;
	color: white;
	border-radius: 5px;
	margin: auto 10px !important;
}