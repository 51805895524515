.choose-subscription {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #f5f5f5;
}

.choose-subscription>.all-subscriptions {
	background: white;
	padding: 10px;
	border-radius: 15px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	text-align: center;
	margin: 40px;
}

.choose-subscription>.all-subscriptions>.actions {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.choose-subscription>.all-subscriptions>.actions>p {
	margin: 0;
}

.choose-subscription>.all-subscriptions>.actions>button {
	padding: 10px 25px;
	border: none;
	outline: none;
	background-color: #5E5CCD;
	font-size: 1.25rem;
	border-radius: 5px !important;
	cursor: pointer;
	color: white;
	border-radius: 5px;
	margin: auto 10px !important;
}