button {
	cursor: pointer;
}

::-webkit-scrollbar-track {
	background: transparent !important;
	border-radius: 50px;
}

::-webkit-scrollbar-thumb {
	background: transparent !important;
	border-radius: 50px;
}

.dashboard>.dashboard-container>.card {
	width: 100%;
	background: #fff;
	border-radius: 10px;
	padding: 5px 10px;
	margin: 10px;
	box-shadow: 0px 0px 50px #2a599805;
}