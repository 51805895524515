.forgot-password-wrapper {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #f5f5f5;
}

.forgot-password-wrapper>.forgot-password-form-container {
	width: 510px;
	background: white;
	padding: 45px 30px;
	border-radius: 15px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.forgot-password-wrapper>.forgot-password-form-container>h1 {
	font-size: 24px;
	text-align: center;
	margin: 0;
	font-weight: bold;
}

.forgot-password-wrapper>.forgot-password-form-container>h4 {
	text-align: center;
	font-size: 16px;
	font-weight: 400;
}

.forgot-password-wrapper>.forgot-password-form-container>.forgot-password-form {
	display: block;
	min-width: 450px;
	max-width: 90%;
	margin-top: 25px;
}

.forgot-password-wrapper>.forgot-password-form-container>.forgot-password-form>button {
	margin-top: 10px;
	width: 100%;
	text-align: center;
	background: #6773E7;
	padding: 10px 0;
	font-size: 1.25rem;
	color: white;
	border-radius: 5px;
	border: none;
	cursor: pointer;
}


.forgot-password-wrapper>.forgot-password-form-container>.forgot-password-form>span {
	font-size: 13px;
	color: red;
	display: none;
}

.forgot-password-wrapper>.forgot-password-form-container>.forgot-password-form>input:invalid[focused="true"]~span {
	display: block;
}

.forgot-password-wrapper>.forgot-password-form-container>.forgot-password-form>input:invalid[focused="true"]~.eyeIcon {
	position: absolute;
	left: 90%;
	bottom: 75px;
	font-size: 20px;
	cursor: pointer;
}



.forgot-password-wrapper>.forgot-password-form-container>.forgot-password-form>.form-group {
	margin-top: 10px;
	position: relative;
}

.forgot-password-wrapper>.forgot-password-form-container>.forgot-password-form>.form-group>label {
	display: flex;
	margin-bottom: 10px;
	align-items: center;
	justify-content: space-between;
	font-size: 1rem;
	font-weight: 500;
}


.forgot-password-wrapper>.forgot-password-form-container>.forgot-password-form>.form-group>span {
	color: #6773E7;
}

.forgot-password-wrapper>.forgot-password-form-container>.forgot-password-form>.form-group>input {
	padding: 10px;
	width: 100%;
	border: 1px solid #ebebeb;
	border-radius: 5px;
	height: 50px;
	margin-bottom: 10px;
}

.forgot-password-wrapper>.forgot-password-form-container>.forgot-password-form>.error {
	border-radius: 5px;
	border: none;
	padding: 5px;
	color: red;
	font-weight: 600;
	font-size: 15px;
	text-align: center;
}

.forgot-password-wrapper>.forgot-password-form-container>.forgot-password-form>.success {
	border-radius: 5px;
	border: none;
	padding: 5px;
	color: green;
	font-weight: 600;
	font-size: 15px;
	text-align: center;
}