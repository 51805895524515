@import "~bootstrap/scss/bootstrap";

@include media-breakpoint-down(md) {
	.input-box {
		flex-direction: 'column'
	}

	.form-control {
		width: '100%'
	}
}

.input-box {
	display: flex;
	width: 100%;
	margin: 10px;
	border-radius: 5px !important;
}

.input-box>.form-control {
	width: 100%;
	margin: 10px;
	border-radius: 5px !important;
	display: flex;
	flex-direction: column;
}

.input-box>.form-control>input {
	border-radius: 5px !important;
	font-size: 16px;
	padding: 10px;
}

.new-plan-header {
	margin: 10px;
	margin-bottom: 0;
}

.new-plan-header>h2 {
	letter-spacing: 2px;
	margin-bottom: 0 !important;
	text-decoration: underline;
}

.create-template>.actions>button {
	background-color: #7983FD;
	border-radius: 5px !important;
}