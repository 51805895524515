.introjs-skipbutton {
	background-color: black;
	color: white !important;
	font-size: 14px !important;
	margin: 10px;
	line-height: normal !important;
	height: fit-content !important;
	border-radius: 3px;
	padding: 5px 0;
}

.introjs-disabled {
	background-color: #f4f4f4 !important;
}

.introjs-tooltiptext {
	font-family: 'Montserrat' !important;
}

.introjs-button {
	background-color: #7983FD !important;
	color: white !important;
	text-shadow: none;
	font-family: 'Montserrat' !important;
}