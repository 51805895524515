.payment {
	background: red;
}

.export {
	background: #6773E7;
	color: white;
	border: none;
	border-radius: 4px;
	padding: 8px 16px;
	font-size: 1rem;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}

.export:hover {
	opacity: 0.9;
}