.templates-container>.delete-popup {
	z-index: auto;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background: rgba(0, 0, 0, 0.5);
}

.templates-container>.delete-popup>.template-delete-container {
	position: fixed;
	background: white;
	width: 45%;
	top: 35%;
	left: 50%;
	transform: translateX(-50%);
	height: auto;
	border-radius: 10px;
	padding: 100px 30px;
}

.templates-container>.delete-popup>.template-delete-container>svg {
	position: absolute !important;
	top: 30px !important;
	right: 30px !important;
	font-size: 1.5rem !important;
	color: #bcbec6 !important;
	cursor: pointer !important;
}

.templates-container>.delete-popup>.template-delete-container>div {
	text-align: center;
}

.templates-container>.delete-popup>.template-delete-container>div>div {
	display: flex;
	justify-content: space-between;
	width: 50%;
	margin: auto;
}

.templates-container>.delete-popup>.template-delete-container>div>div>button {
	border: 1px solid #6773E7;
	border-radius: 5px;
	padding: 6px 7px;
	outline: none;
	cursor: pointer;
	font-size: 14px;
	font-weight: 500;
	width: 49%;
}

.templates-container>.delete-popup>.template-delete-container>div>div>button:first-child {
	background-color: #6773E7;
	color: white;
}

.templates-container>.delete-popup>.template-delete-container>div>div>button:last-child {
	background: white;
	color: #000;
	border: 1px solid #000;
}

.upload_template {
	width: 100%;
	padding: 50px;
	margin: 0 auto;
	text-align: center;
	border: 1px dotted #7983FD;
}

.upload_template>button {
	background: #6773E7;
	padding: 10px;
	font-size: 1.25rem;
	color: white;
	cursor: pointer;
	border-radius: 5px !important;
}