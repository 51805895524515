@import "~bootstrap/scss/bootstrap";

@include media-breakpoint-down(md) {
	.editor-container {
		flex-direction: column-reverse;
	}

	.gjs-pn-buttons {
		display: flex !important;
		flex-direction: row !important;
	}

	.sidenav {
		width: fit-content;
	}
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	position: sticky;
	top: 0;

	.logo {
		height: 60px;
		width: 180px;
		display: flex;
		align-items: center;
	}

	h2 {
		display: flex;
		align-items: center;
		margin-left: 25px;
		letter-spacing: 2px;
	}

	.logo>img {
		height: 100%;
		width: 100%;
	}
}

::-webkit-scrollbar-track {
	background: white !important;
	border-radius: 50px;
}

.sidenav {
	top: 20;
	left: 0;
	width: 25%;
	height: 100vh;
	overflow: scroll;
	border: none;
	-ms-overflow-style: none;
	background-color: rgba(255, 255, 255, 0.95);
	transition: 0.5s;

	.pages {
		height: 100px;
		overflow: scroll;
		overflow-x: hidden;
	}
}

#Model-Editor>.modal-content>.modal-header {
	padding: 0 !important;
}

.modeleditorbutton {
	padding: 10px;
	border: none;
	outline: none;
	background-color: #5E5CCD;
	font-size: 1rem;
	border-radius: 5px;
	cursor: pointer;
	color: white;
	border-radius: 5px;
}

.editor-container>#editor {
	font-family: '"Poppins", sans-serif';
}

.w-75 {
	width: 75%;
}

.start-25 {
	left: 25%;
}

.main-content {
	box-shadow: 0px 0px 10px -6px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 0px 0px 10px -6px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 10px -6px rgba(0, 0, 0, 0.75);
	height: 80vh;
	overflow: scroll;
}

#editor-actions>button {
	width: 100% !important;
}

.gjs-pn-panel {
	position: relative;
}

.gjs-cv-canvas {
	width: 100%;
	height: 100%;
	top: 0;
}

.tab-content {
	display: contents;
}

.gjs-clm-tags {
	display: none;
}

.gjs-trt-trait__wrp- {
	display: none;
}

#block {
	height: 100%;

	#blocks {
		height: 100%;

		.gjs-blocks-c {
			align-items: center;
			justify-content: center;
		}

		.gjs-block {
			justify-content: center;
		}
	}
}

/* Theming */
.gjs-one-bg {
	background-color: white;
}

.gjs-two-color {
	color: #222943;
}

.gjs-three-bg {
	background-color: #222943;
	color: #fcf6f5ff;
}

.gjs-four-color,
.gjs-four-color-h:hover {
	color: #7983FD !important;
}

.gjs-pn-btn {
	width: 100% !important;
	border: 1px solid lightgray;
	border-radius: 5px;
	margin: 5px;
	box-shadow: 0px 0px 10px -11px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 0px 0px 10px -11px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 10px -11px rgba(0, 0, 0, 0.75);
}

.gjs-pn-buttons {
	display: block;
}

// Customize Bootstrap CSS

.btn,
.nav-link,
.modal-content,
.form-control {
	border-radius: 0 !important;
}

.btn {
	.fa {
		color: #222943 !important;
	}

	&:hover {
		.fa {
			color: #fcf6f5ff;
		}
	}
}

/** Error **/
.error {
	.bg-body {
		min-height: 150px;
		max-height: 150px;
		display: flex;
		align-items: center;
		justify-content: center;

		.title {
			font-weight: 600;
		}
	}

	.btn {
		border-radius: 40px !important;
		padding: 15px 20px;
		font-size: 14px;
		font-weight: 700;
		min-width: 150px;
	}
}


.gjs-am-preview-cont {
	margin-top: 1rem;
}

.gjs-block-label {
	.fa {
		font-size: 25px;
	}
}

.gjs-blocks-cs {
	margin: 5px !important;
}

.gjs-block-category {
	border-radius: 5px;
}

.gjs-sm-field input,
.gjs-clm-select input,
.gjs-clm-field input,
.gjs-sm-field select,
.gjs-clm-select select,
.gjs-clm-field select,
#gjs-sm-add,
.gjs-sm-btn {
	color: black !important;
}

.gjs-title,
.gjs-sm-sector-title {
	background-color: white !important;
	border: none !important;
	font-weight: normal !important;
}

// .grp-wrapper {
// 	background-image: url("data:image/png:base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==");
// }

.grp-preview {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: crosshair;
}

.grp-handler {
	width: 4px;
	margin-left: -2px;
	user-select: none;
	height: 100%;
	-webkit-user-select: none;
	-moz-user-select: none;
}

.grp-handler-close {
	color: rgba(0, 0, 0, 0.4);
	border-radius: 0 2px 10px rgba(0, 0, 0, 0.25);
	background-color: red;
	text-align: center;
	width: 15px;
	height: 15px;
	margin-left: -5px;
	line-height: 10px;
	font-size: 21px;
	cursor: pointer;
}

.grp-handler-close {
	position: absolute;
	top: -17px;
}

.grp-handler-drag {
	background-color: rgba(0, 0, 0, 0.5);
	cursor: col-resize;
	width: 100%;
	height: 100%;
}

.grp-handler-selected .grap-handler-drag {
	background-color: rgba(255, 255, 255, 0.5);
}

.grp-handler-cp-c {
	display: none;
}

.grp-handler-selected .grp-handler-cp-c {
	display: block;
}

.grp-handler-cp-wrap {
	width: 15px;
	height: 15px;
	margin-left: -8px;
	border: 3px solid #fff;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
	overflow: hidden;
	border-radius: 100%;
	cursor: pointer;
}

.grp-handler-cp-wrap input[type="color"] {
	opacity: 0;
	cursor: pointer;
}