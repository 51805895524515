.project-view {
	height: 100%;
}

.project-view>.project-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.project-view>#project-body {
	color: gray;
	font-size: 14px;
}

.project-view>#project-body>.share {
	display: flex;
	align-items: center;
}

.project-view>#project-body>.share>.column {
	flex: 50%;
}

.project-view>#project-body>.share>.column>.project-link>div>.inputField {
	border: 1px solid;
	font-size: 14px;
	padding: 10px;
	border-radius: 5px;
	width: 100%;
}

.project-view>#project-body>.share>.column>.social-share>.social-button {
	margin: 10px !important;
}


Button {
	margin: 2px !important;
}

.description {
	font-size: 1.2rem;
	letter-spacing: 2px;
	color: black;
}

.copyToClipboard {
	color: white !important;
	font-size: 14px;
	width: 100%;
	background: #7980F5;
	padding: 15px;
	border-radius: 5px;
	width: 100%;
	border: none;
}

#QR-code {
	--b: 5px;
	/* thickness of the border */
	--c: black;
	/* color of the border */
	--w: 30px;
	/* width of border */


	border: var(--b) solid #0000;
	/* space for the border */
	--_g: #0000 90deg, var(--c) 0;
	--_p: var(--w) var(--w) border-box no-repeat;
	background:
		conic-gradient(from 90deg at top var(--b) left var(--b), var(--_g)) 0 0 / var(--_p),
		conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100% 0 / var(--_p),
		conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0 100% / var(--_p),
		conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g)) 100% 100% / var(--_p);

	/*Irrelevant code*/
	width: fit-content;
	height: fit-content;
	box-sizing: border-box;
	margin: 5px;
	display: inline-flex;
	font-size: 30px;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 10px;
}