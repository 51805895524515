.template-card-wrapper {
	background: white;
	padding: 10px;
	border-radius: 10px;
	height: 100% !important;
	margin: 5px;
	box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
	margin: 10px;
}

.template-card-wrapper>h1 {
	font-size: 1.25rem;
	font-weight: 600;
	margin: 0;
}

.template-card-wrapper>p {
	font-size: 0.75rem;
	font-weight: 400;
}

.template-card-wrapper>div {
	display: flex;
	justify-content: space-between;
}

.template-card-wrapper>div>button {
	border: 1px solid #7983FD;
	border-radius: 5px;
	padding: 7px 10px;
	outline: none;
	cursor: pointer;
	font-size: 14px;
	font-weight: 500;
	margin-right: 2px;
}

.template-card-wrapper>div>button:first-child {
	background: #7983FD;
	color: white;
}

.template-card-wrapper>div>button:last-child {
	background: white;
	color: #000;
	border: 1px solid #000;
}