.register-wrapper {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #f5f5f5;
}

.register-wrapper>.register-form-container {
	width: 510px;
	background: white;
	padding: 15px 30px;
	border-radius: 15px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.register-wrapper>.register-form-container>h1 {
	font-size: 24px;
	text-align: center;
	margin: 0;
	font-weight: bold;
}

.register-wrapper>.register-form-container>h4 {
	text-align: center;
	font-size: 16px;
	font-weight: 400;
	margin-top: 0;
}

.register-wrapper>.register-form-container>span {
	font-weight: bold;
	color: #6773E7;
	margin-right: 5px;
	cursor: pointer;
}

.register-wrapper>.register-form-container>.register-form {
	display: block;
	min-width: 450px;
	max-width: 90%;
	margin-top: 25px;
}

.register-wrapper>.register-form-container>.register-form>.formgroup {
	margin-top: 10px;
	position: relative;
}

.register-wrapper>.register-form-container>.register-form>.formgroup>label {
	display: flex;
	margin-bottom: 10px;
	align-items: center;
	justify-content: space-between;
	font-size: 1rem;
	font-weight: 500;
}

.register-wrapper>.register-form-container>.register-form>.formgroup>span {
	color: #6773E7;
}

.register-wrapper>.register-form-container>.register-form>.formgroup>a {
	text-decoration: none;
	cursor: pointer;
}

.register-wrapper>.register-form-container>.register-form>.formgroup>input {
	padding: 10px;
	width: 100%;
	border: 1px solid #ebebeb;
	border-radius: 5px;
	height: 50px;
	margin-bottom: 10px;
}

.register-wrapper>.register-form-container>.register-form>.formgroup>.eyeIcon {
	position: absolute;
	left: 90%;
	bottom: 16%;
	font-size: 20px;
	cursor: pointer;

}

.register-wrapper>.register-form-container>.register-form>.formgroup>.errorMessage {
	font-size: 13px;
	color: red;
	display: none;
}

.register-wrapper>.register-form-container>.register-form>.formgroup>input:invalid[focused="true"]~span {
	display: block;
}

.register-wrapper>.register-form-container>.register-form>.register-buttons {
	display: flex;
	flex-direction: column;
	text-align: center;
}

.register-wrapper>.register-form-container>.register-form>.formgroup>input:invalid[focused="true"]~.eyeIcon {
	position: absolute;
	left: 90%;
	bottom: 75px;
	font-size: 20px;
	cursor: pointer;
}

.register-wrapper>.register-form-container>.register-form button {
	background: #6773E7;
	padding: 10px 0;
	font-size: 1.25rem;
	color: white;
	border-radius: 5px;
	cursor: pointer;
}

.register-wrapper>.register-form-container>.register-form button:hover {
	opacity: 0.8;
}

.register-wrapper>.register-form-container>.register-form>.register-buttons>.orDivider {
	margin: 10px 0;
	display: flex;
	text-align: center;
	align-items: center;
	font-size: 1.25rem;
}

.register-wrapper>.register-form-container>.register-form>.register-buttons>.orDivider>span {
	display: block;
	height: 1px;
	width: 40%;
	margin: auto;
	background: lightgrey;
	border-radius: 25px;
}

.register-wrapper>.register-form-container>.register-form>.register-buttons>.social-button {
	background: #ffffff;
	border: 1px solid #ebebeb;
	padding: 10px 0;
	font-size: 1.25rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	cursor: pointer;
	color: black;
}

.register-wrapper>.register-form-container>.register-form>.register-buttons>.social-button>img {
	width: 25px;
	margin-right: 15px;
}

.register-wrapper>.register-form-container>.register-form>.register-buttons>.social-button:hover {
	opacity: 0.8;
}