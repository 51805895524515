.all_plans>.header {
	margin: 0;
	padding: 0;
	height: fit-content;
	position: relative;
}

.all_plans>.header>.price-code>select {
	padding: 10px;
	border-radius: 10px !important;
	margin-left: 5px;
	cursor: pointer;
}

.plans {
	width: 100%;
	overflow: scroll;
}

.plans>.plans-container {
	display: flex;
	align-items: stretch;
}

.plans>.plans-container>#plan-label-card {
	background-color: white;
	width: 200px;
	text-align: center;
	cursor: pointer;
	padding: 5px;
	margin: 10px;
	transition: 0.5s ease-in-out;
	text-align: center;
	box-shadow: none !important;
	border: none;
	border-radius: 5px;
}

.plans>.plans-container>#plan-label-card>.plan_title {
	text-transform: uppercase;
	letter-spacing: 2px;
	text-align: center;
	color: white;
}

.plans>.plans-container>#plan-label-card>.plan_title>h5 {
	margin: 0;
	margin-top: 5px;
	margin-bottom: 5px;
}

.plans>.plans-container>#plan-label-card>.plan_details {
	background-color: white;
	border: 1px solid white;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.plans>.plans-container>#plan-label-card>.plan_details>.title {
	text-transform: uppercase;
	font-weight: 700;
}

.plans>.plans-container>#plan-label-card>.plan_details>.plan_features>ul {
	text-align: left;
	margin: 0 !important;
	margin-top: 5px;
	margin-bottom: 5px;
	padding-inline-start: 0;
}

.plans>.plans-container>#plan-label-card>.plan_details>.plan_features>ul>li {
	margin-bottom: 15px;
	font-size: 12px;
	color: black;
	font-weight: bold;
	list-style-type: none;
}

.plans>.plans-container>#plan-label-card>.plan_details>.plan_features>ul>li>span {
	color: red;
	font-size: 14px;
}

.plans>.plans-container>#plan-label-card>.plan_details>.plan_features>ul>.price {
	font-weight: 600;
	font-size: 2rem;
	color: #5E5CCD;
}

.plans>.plans-container>#plan-label-card>.plan_details>.plan_features>ul>hr {
	margin: 12px auto;
}

.plans>.plans-container>#plan-label-card>.plan_details>.price {
	font-weight: 300;
}

.plans>.plans-container>#plan-label-card>.plan_details>.price>p {
	margin: 3px;
}

.plans>.plans-container>#plan-label-card>.plan_details>.price>h6 {
	margin-top: 0;
	margin-bottom: 0;
}

.plans>.plans-container>#plan-label-card>.plan_details>.price>p>span {
	font-size: 25px;
	font-weight: bold;
}

.plans>.plans-container>#plan-label-card>.plan_details>.actions>.dummy {
	width: 100%;
	background-color: white !important;
	border: none;
}

.plans>.plans-container>#plan-label-card>.plan_details>.actions {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.plans>.plans-container>#plan-label-card>.plan_details>.actions>button {
	padding: 10px;
	border: none;
	outline: none;
	background: #6773E7;
	color: white;
	font-size: 18px;
	border-radius: 5px;
	cursor: pointer;
	width: 100%;
}

.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: lightgray;
	-webkit-transition: .4s;
	transition: .4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 20px;
	width: 20px;
	left: 4px;
	bottom: 4px;
	background-color: #6773E7;
	-webkit-transition: .4s;
	transition: .4s;
}

input:checked+.slider {
	background-color: #23374D;
}

input:focus+.slider {
	box-shadow: 0 0 1px #23374D;
}

input:checked+.slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 40px;
}

.slider.round:before {
	border-radius: 50%;
}