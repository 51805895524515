.subscription {
	width: 80% !important;
	margin: 0 auto;
	text-align: center;
	background-color: white;

}

#subscription_plan_header {
	width: fit-content;
	cursor: pointer;
	padding: 5px;
	transition: 0.5s ease-in-out;
}

#subscription_plan_header:hover {
	background-color: gray;
	background-image: linear-gradient(to bottom, transparent 0%, #fff 100%);
}

.subscription_plan_title {
	text-transform: uppercase;
	letter-spacing: 2px;
	border: 1px solid gray;
}

.subscription_plan_title>h5 {
	margin: 0;
	margin-top: 5px;
	margin-bottom: 5px;
}

.details {
	display: flex;
	align-items: center;
	/* justify-content: space-between; */
}

.subscription_plan_card>.details>.subscription_details {
	background-color: white;
	height: 100%;
	text-align: start !important;
	cursor: pointer;
	padding: 5px;
	margin: 10px;
	transition: 0.5s ease-in-out;
	text-align: center;
	box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.75);
	border: none;
	border-radius: 5px;
}