.create-it-project-container>.create-it-project-form {
	background: white;
	padding: 10px;
	width: 100%;
}

.create-it-project-container>.create-it-project-form>.row {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 15px;
}

h3 {
	margin: 0 0 5px 0;
	font-size: 1.3rem !important;
}

.create-it-project-form~.input-field {

	border: 1px solid;
	font-size: 14px;
	padding: 10px;
	border-radius: 5px;
	width: 100%;
	margin-bottom: 20px;

}

.create-it-project-container>.create-it-project-form>.file_box>div>input[type="file"]::file-selector-button {
	padding: 10px;
	border: none;
	outline: none;
	background-color: #5E5CCD;
	font-size: 1rem;
	border-radius: 5px;
	cursor: pointer;
	color: white;
	border-radius: 5px;
}

.create-it-project-container>.create-it-project-form>.actions {
	padding: 0;
	margin-top: 30px;
}

.create-it-project-container>.create-it-project-form>.actions>.createButton,
.create-it-project-container button {
	padding: 10px;
	border: none;
	outline: none;
	background-color: #5E5CCD;
	font-size: 1rem;
	border-radius: 5px;
	cursor: pointer;
	color: white;
	border-radius: 5px;
}

.create-it-project-container>.create-it-project-form>.FaqContainer {
	width: 100%;
	margin: auto;
	margin-top: 30px;
}

.create-it-project-container>.create-it-project-form>.FaqContainer>.FaqItem {
	margin-top: 10px;
}

.create-it-project-container>.create-it-project-form>.FaqContainer>.FaqItem>.Question {
	background: #5E5CCD;
	padding: 10px;
	border-radius: 5px;
	color: white !important;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 20px;
}

.create-it-project-container>.create-it-project-form>.FaqContainer>.FaqItem>.Question>p {
	padding: 0;
	margin: 0;
	font-size: 1rem;
}

@keyframes fadeIn {
	from {
		transform: translateY(-20px)
	}

	to {
		transform: translateY(0px);
	}
}

.create-it-project-container>.create-it-project-form>.FaqContainer>.FaqItem>.Answer {

	padding: 2px 15px;
	background: #ececec;
	border-radius: 0 0 15px 15px;
	display: none;
	animation: fadeIn 0.3s ease-in-out;
	font-size: 16px;
	font-family: 'Montessarat';
	white-space: pre-wrap;

}

.create-it-project-container>.create-it-project-form>.FaqContainer>.FaqItem>.active {
	display: block;
}

.create-it-project-container>.create-it-project-form>#create-it-model-choose>.model_box {
	display: flex;
	align-items: center;
}

.create-it-project-container>.create-it-project-form>#create-it-model-choose>.model_box>label {
	margin: 10px;
}