@import "~bootstrap/scss/bootstrap";

@include media-breakpoint-down(md) {
	.sidebar-wrapper {
		width: 100% !important;
		padding: 10px !important;
	}

	.sidebar-wrapper>.top-holder>img {
		width: 35% !important;
		height: 60px;
	}

	.sidebar-wrapper>.top-holder>.mobile-menu {
		display: block !important;
		width: 40px;
		padding: 2px;
		background: none;
		border: 1px solid #fff;
		border-radius: 5px;
		cursor: pointer;
		height: fit-content;

		span {
			background: #fff;
			height: 3px;
			width: 80%;
			display: block;
			margin: 5px auto;
			border-radius: 50px;
		}
	}
}


.sidebar-wrapper {
	width: 200px;
	background: #131d40;
	box-shadow: 0px 0px 50px #2a59981a;
	border-radius: 15px;
	padding: 20px;
	transition: all 0.3s ease-in-out;
}

.sidebar-wrapper>.top-holder {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.sidebar-wrapper>.top-holder>img {
	width: 100%;
}

.sidebar-wrapper>.top-holder>.mobile-menu {
	display: none;
}

.menus {
	margin-top: 35px;
}

.menu-item {
	color: #ffffff;
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 25px;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.menu-item>svg {
	margin-right: 10px;
}

// .menu-item:last-child {
// 	position: absolute;
// 	bottom: 0;
// }