#all_plan_card {
	background-color: white;
	width: 250px;
	text-align: center;
	cursor: pointer;
	padding: 5px;
	margin: 10px;
	transition: 0.5s ease-in-out;
	text-align: center;
	box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.75);
	border: none;
	border-radius: 5px;
}

#all_plan_card>.plan_title {
	text-transform: uppercase;
	letter-spacing: 2px;
	text-align: center;
}

#all_plan_card>.plan_title>h5 {
	margin: 0;
	margin-top: 5px;
	margin-bottom: 5px;
}

#all_plan_card>.plan_details {
	background-color: white;
	border: 1px solid white;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

#all_plan_card>.plan_details>.title {
	text-transform: uppercase;
	font-weight: 700;
}

#all_plan_card>.plan_details>.plan_features>ul {
	text-align: center;
	margin: 0 !important;
	margin-top: 5px;
	margin-bottom: 5px;
	padding-inline-start: 0;
}

#all_plan_card>.plan_details>.plan_features>ul>li {
	margin-bottom: 15px;
	font-size: 12px;
	color: gray;
	font-weight: bold;
	list-style-type: none;
}

#all_plan_card>.plan_details>.plan_features>ul>li>span {
	color: red;
	font-size: 14px;
}

#all_plan_card>.plan_details>.plan_features>ul>.price {
	font-weight: 600;
	font-size: 2rem;
	color: #5E5CCD;
}

#all_plan_card>.plan_details>.plan_features>ul>hr {
	width: 80%;
	margin: 10px auto;
}

#all_plan_card>.plan_details>.price {
	font-weight: 300;
}

#all_plan_card>.plan_details>.price>p {
	margin: 3px;
}

#all_plan_card>.plan_details>.price>h6 {
	margin-top: 0;
	margin-bottom: 0;
}

#all_plan_card>.plan_details>.price>p>span {
	font-size: 25px;
	font-weight: bold;
}

#all_plan_card>.plan_details>.actions {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

#all_plan_card>.plan_details>.actions>button {
	padding: 10px;
	border: none;
	outline: none;
	background: #6773E7;
	color: white;
	font-size: 18px;
	border-radius: 5px;
	cursor: pointer;
	width: 100%;
}