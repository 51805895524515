.model>.model-view {
	height: 100%;
}

.model-view>#model-body {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.model-view>#model-body>.model-viewer {
	flex: 1;
}

.model-view>#model-body>.model-viewer>model-viewer {
	height: 300px !important;
	width: 100% !important;
}

.model-view>#model-body>.model-details {
	flex: 1;
}

.model-view>#model-body>.model-details>table {
	table-layout: fixed;
	width: 100%;
	border: 1px solid black;
}

.model-view>#model-body>tr,
td {
	padding: 10px;
	word-wrap: break-word;
}

.model-view>#model-body>.model-details>table>tbody>tr>.label {
	width: 160px !important;
	font-weight: bolder;
	font-size: 1rem !important;
}

.model-view>#model-body>.model-details>table>tbody>tr>.content {
	font-size: 1.1rem !important;
}