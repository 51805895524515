.view_template {
	padding: 10px;
}

.view_template>.header {
	padding: 0;
	margin-bottom: 5px;
	display: flex;
	justify-content: space-between;
}

.view_template>.header>button {
	border: 1px solid #7983FD;
	border-radius: 5px;
	padding: 5px;
	outline: none;
	cursor: pointer;
	font-size: 14px;
	font-weight: 500;
	margin-right: 2px;
}