.model-editor {
	display: flex;
}

.model-editor>.sidebar {
	flex: 0.5;
}

.editor-view {
	width: 100%;
	border: 1px solid black;
	padding: 10px;
	flex: 2;
	display: flex;
	flex-direction: column;
	/* justify-content: space-between; */
}

.editor-view>.model-editor-panel {
	display: flex;
	align-items: center;
	justify-content: start;
}

.editor-view>#threedmodels {
	padding: 10px;
	display: flex;
	flex-wrap: wrap;
}

.editor-view>#threedmodels>.model {
	border: 1px solid gray;
	background-color: white;
	border-radius: 5px;
	padding: 5px;
	margin: 5px;
	margin-bottom: 0;
	font-weight: 500;
	letter-spacing: 2px;
	cursor: pointer;
	width: fit-content;
}

.editor-view>#threedmodels>.current-model {
	background-color: #7980F5 !important;
	color: white !important;
}

.editor-view>.model-editor-panel>#model-viewer {
	width: 100%;
}

.editor-view>.model-editor-panel>.header {
	height: 100%;
	display: block;
	border-radius: 5px;
	border: 1px solid lightgray;
	box-shadow: 0px 0px 10px -6px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 0px 0px 10px -6px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 10px -6px rgba(0, 0, 0, 0.75);
}

model-viewer {
	height: 400px !important;
	width: 100% !important;
	margin-bottom: 20px;
}


.bs-button {
	border-radius: 5px !important;
	color: white !important;
	font-size: 12px;
	width: 100%;
	background: #7980F5;
}