@import "~bootstrap/scss/bootstrap";

@include media-breakpoint-down(md) {
	.layout-wrapper {
		flex-direction: column;

		main {
			margin-left: 0;
			height: auto;
		}
	}

	.layout-wrapper>.main-wrapper {
		width: 100% !important;
	}
}

.layout-wrapper {
	width: 100%;
	display: flex;
	background: #f9fafe;
	height: 100%;
	padding: 15px;
	border-radius: 15px;
}

.layout-wrapper>.main-wrapper {
	width: calc(100% - 200px);
	padding-left: 10px;
	display: flex;
	flex-direction: column;
}

.layout-wrapper>.main-wrapper>main {
	display: flex;
	flex-direction: column;
	height: 86vh;
	overflow: auto;
}

.layout-wrapper>.main-wrapper>main ::-webkit-scrollbar {
	display: none;
}