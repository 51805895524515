.add-model-form {
    background: white;
    padding: 10px;
    width: 100%;
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

h3 {
    margin: 0 0 5px 0;
    font-size: 1.3rem !important;
}

.resetButton {
    padding: 10px 25px;
    border: none;
    outline: none;


    font-size: 1.25rem;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
    background-color: #868aac;
}

.input-field {

    border: 1px solid;
    font-size: 14px;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 20px;

}

.input-file {
    padding: 20px;
    margin: 20px;
    border: 1px dotted gray;
}

.modal {
    border-radius: 5px !important;
}

.file-box {
    margin: 10px !important;
    padding: 5px;
    width: fit-content !important;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
}

.or-field {
    /* text-align: left */
    padding: 50px 0 0;
    margin: 10px;
}

.add-model-form>form>.form>.actions {
    padding: 0;
    margin-top: 30px;
}

.add-model-form>form>.form>.actions>.createButton {
    padding: 10px;
    border: none;
    outline: none;
    background-color: #5E5CCD;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    border-radius: 5px;
}

.add-model-form>.FaqContainer {
    width: 100%;
    margin: auto;
    margin-top: 30px;
}

.add-model-form>.FaqContainer>.FaqItem {
    margin-top: 10px;
}

.add-model-form>.FaqContainer>.FaqItem>.Question {
    background: #5E5CCD;
    padding: 10px;
    border-radius: 5px;
    color: white !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
}

.add-model-form>.FaqContainer>.FaqItem>.Question>p {
    padding: 0;
    margin: 0;
    font-size: 1rem;
}

@keyframes fadeIn {
    from {
        transform: translateY(-20px)
    }

    to {
        transform: translateY(0px);
    }
}

.add-model-form>.FaqContainer>.FaqItem>.Answer {

    padding: 2px 15px;
    background: #ececec;
    border-radius: 0 0 15px 15px;
    display: none;
    animation: fadeIn 0.3s ease-in-out;
    font-size: 16px;
    font-family: 'Montessarat';
    white-space: pre-wrap;

}

.add-model-form>.FaqContainer>.FaqItem>.active {
    display: block;
}