.models-wrapper {
	display: flex;
	overflow-y: scroll;
	cursor: pointer;
}

.models-wrapper>.product-wrapper {
	display: flex;
}

.models-wrapper>.product-wrapper>.product-card {
	background: white;
	box-shadow: 0px 2px 22px #dce3ea;
	padding: 15px;
	border-radius: 10px;
	transition: 0.2s ease-in-out all;
	cursor: pointer;
	width: 100%;
	margin: 5px 10px;
}

.models-wrapper>.product-wrapper>.product-card>img {
	height: 150px;
	width: 100%;
	background: #6773E7;
	border-radius: 10px;
	padding: 10px;
}

.models-wrapper>.product-wrapper>.product-card>h3 {
	font-size: 1.2rem;
	margin-left: 15px;
	font-weight: 400;
	margin-bottom: 0;
	margin-top: 10px;
}

.models-wrapper>.product-wrapper>.product-card>h5 {
	font-size: 0.8rem;
	margin-left: 15px;
	font-weight: 400;
	margin-top: 5px;
	margin-bottom: 0;
}

.models-wrapper>.product-wrapper>.product-card>div {
	transition: 0.3s ease-in-out all;
	opacity: 0;
	height: 0;
}

.models-wrapper>.product-wrapper>.product-card>p {
	font-size: 0.9rem;
	margin-left: 15px;
	font-weight: 400;
	margin-top: 10px;
}

.models-wrapper>.product-wrapper>.product-card>button {
	display: block;
	font-size: 1rem;
	width: 90%;
	margin: auto;
	margin-top: 15px;
	background: #3892FF;
	color: white;
	border: none;
	outline: none;
	border-radius: 5px;
	padding: 15px 20px;
}

.models-wrapper>.product-wrapper>.product-card>:hover {
	opacity: 0.8;
}

.models-wrapper>.product-wrapper>.product-card>div:hover {
	opacity: 1;
	height: auto;
}