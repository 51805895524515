.card {
	background: white;
	padding: 10px;
	margin: 10px;
	border-radius: 10px;
	box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
}

.card>.model-viewer {
	height: 200px;
}

.card>.model-viewer>model-viewer {
	height: 100% !important;
}

.card>.card-body>.actions>button {
	background-color: #6773E7 !important;
	color: white !important;
	border: 1px solid #6773E7 !important;
	border-radius: 5px !important;
	outline: none;
	cursor: pointer;
	font-size: 14px;
	font-weight: 500;
}